var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BigNumber from 'bignumber.js';
var WebSocketState;
(function (WebSocketState) {
    WebSocketState[WebSocketState["CONNECTING"] = 0] = "CONNECTING";
    WebSocketState[WebSocketState["OPEN"] = 1] = "OPEN";
    WebSocketState[WebSocketState["CLOSING"] = 2] = "CLOSING";
    WebSocketState[WebSocketState["CLOSED"] = 3] = "CLOSED";
})(WebSocketState || (WebSocketState = {}));
class WebSocketDataManager {
    constructor() {
        this.ws = null;
        this.dataCache = null;
        this.callbacks = new Set();
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 1000;
        this.connectionState = WebSocketState.CLOSED;
        this.reconnectTimeout = null;
        this.dataPromise = null;
        this.dataResolve = null;
        this.connect();
        window.addEventListener('beforeunload', () => this.disconnect());
    }
    connect() {
        if (this.connectionState === WebSocketState.CONNECTING ||
            this.connectionState === WebSocketState.OPEN) {
            return;
        }
        try {
            this.connectionState = WebSocketState.CONNECTING;
            const wsUrl = `wss://${window.location.hostname}/data`;
            console.log('Attempting WebSocket connection to:', wsUrl);
            this.ws = new WebSocket(wsUrl);
            // Create a promise that will resolve when we get our first data
            if (!this.dataPromise) {
                this.dataPromise = new Promise((resolve) => {
                    this.dataResolve = resolve;
                });
            }
            this.ws.onopen = () => {
                console.log('WebSocket connected');
                this.connectionState = WebSocketState.OPEN;
                this.reconnectAttempts = 0;
            };
            this.ws.onmessage = (event) => {
                try {
                    const newData = JSON.parse(event.data);
                    const hasChanged = this.hasDataChanged(newData);
                    this.dataCache = newData;
                    // Resolve the initial data promise if it exists
                    if (this.dataResolve) {
                        this.dataResolve(newData);
                        this.dataResolve = null;
                    }
                    if (hasChanged) {
                        this.notifyCallbacks();
                    }
                }
                catch (error) {
                    console.error('Error parsing WebSocket data:', error);
                }
            };
            this.ws.onclose = () => {
                console.log('WebSocket closed');
                this.connectionState = WebSocketState.CLOSED;
                this.handleReconnect();
            };
            this.ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        }
        catch (error) {
            console.error('Error connecting to WebSocket:', error);
            this.connectionState = WebSocketState.CLOSED;
            this.handleReconnect();
        }
    }
    handleReconnect() {
        this.reconnectAttempts++;
        const delay = this.reconnectDelay * Math.pow(2, this.reconnectAttempts - 1);
        console.log(`Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts})`);
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        this.reconnectTimeout = setTimeout(() => this.connect(), delay);
    }
    hasDataChanged(newData) {
        if (!this.dataCache)
            return true;
        return Object.keys(newData).some(key => {
            const typedKey = key;
            return this.dataCache[typedKey] !== newData[typedKey];
        });
    }
    subscribe(callback) {
        this.callbacks.add(callback);
        if (this.dataCache) {
            callback(this.dataCache);
        }
        return () => this.unsubscribe(callback);
    }
    unsubscribe(callback) {
        this.callbacks.delete(callback);
    }
    notifyCallbacks() {
        if (!this.dataCache)
            return;
        this.callbacks.forEach(callback => {
            try {
                callback(this.dataCache);
            }
            catch (error) {
                console.error('Error in subscriber callback:', error);
            }
        });
    }
    waitForData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.dataCache) {
                return this.dataCache;
            }
            return this.dataPromise;
        });
    }
    getData(key) {
        var _a;
        return ((_a = this.dataCache) === null || _a === void 0 ? void 0 : _a[key]) || '0';
    }
    getNumericData(key) {
        const value = this.getData(key);
        const parsed = parseFloat(value);
        return isNaN(parsed) ? 0 : parsed;
    }
    getBigNumberData(key) {
        return new BigNumber(this.getData(key));
    }
    getAllData() {
        return this.dataCache;
    }
    isConnected() {
        return this.connectionState === WebSocketState.OPEN;
    }
    disconnect() {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }
        if (this.ws) {
            this.connectionState = WebSocketState.CLOSING;
            this.ws.close();
            this.ws = null;
        }
        this.callbacks.clear();
        this.dataCache = null;
        this.connectionState = WebSocketState.CLOSED;
    }
}
export const websocketDataManager = new WebSocketDataManager();
// Helper function that waits for initial data
export function fetchData(key) {
    return __awaiter(this, void 0, void 0, function* () {
        yield websocketDataManager.waitForData();
        return websocketDataManager.getData(key);
    });
}
export function fetchNumericData(key) {
    return __awaiter(this, void 0, void 0, function* () {
        yield websocketDataManager.waitForData();
        return websocketDataManager.getNumericData(key);
    });
}
export function fetchBigNumberData(key) {
    return __awaiter(this, void 0, void 0, function* () {
        yield websocketDataManager.waitForData();
        return websocketDataManager.getBigNumberData(key);
    });
}
