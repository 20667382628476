var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import idl from './rapr_mvp.json';
import { getProgramState, createPopup } from './utils';
import { connection, programID, usdcMintAddress, raprMintAddress } from './script';
import { fetchData } from './websocketData';
let countdownInterval;
export function getUserState(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!wallet) {
            console.log('No wallet provided to getUserState');
            return null;
        }
        try {
            console.log('Fetching user state for wallet:', wallet.toBase58());
            const provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
            const program = new Program(idl, programID, provider);
            const [userState] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
            console.log('User state PDA:', userState.toBase58());
            try {
                const account = yield program.account.userState.fetch(userState);
                console.log('User state fetched:', account);
                return account;
            }
            catch (error) {
                console.log('User state not initialized yet');
                return null;
            }
        }
        catch (error) {
            console.error('Error in getUserState:', error);
            return null;
        }
    });
}
export function purchaseTickets(wallet, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Initiating ticket purchase for amount:', amount);
        const provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const amountBN = new BN(amount);
        console.log('Amount as BN:', amountBN.toString());
        const userUsdcAccount = yield getAssociatedTokenAddress(usdcMintAddress, wallet);
        console.log('User USDC account:', userUsdcAccount.toBase58());
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state PDA:', programState.toBase58());
        const [userState] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
        console.log('User state PDA:', userState.toBase58());
        const state = yield getProgramState();
        if (!state)
            throw new Error('Failed to fetch program state');
        console.log('Creating purchase instruction...');
        const purchaseIx = yield program.methods.purchaseTickets(amountBN)
            .accounts({
            userState: userState,
            userUsdcAccount: userUsdcAccount,
            userAuthority: wallet,
            usdcTokenAccount: state.usdcTokenAccount,
            programState: programState,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
            .instruction();
        const tx = new Transaction().add(purchaseIx);
        tx.feePayer = wallet;
        const latestBlockhash = yield connection.getLatestBlockhash();
        tx.recentBlockhash = latestBlockhash.blockhash;
        console.log('Transaction built:', tx);
        return tx.serialize({ verifySignatures: false }).toString('base64');
    });
}
function redeemTickets(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Initiating ticket redemption');
        const provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state:', programState.toBase58());
        const [userStatePDA] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
        console.log('User state PDA:', userStatePDA.toBase58());
        const treasuryAccount = new PublicKey('48vhfaVRjbhErMwfCh59Ka2aktoHUojT3Maovb8bd34C');
        console.log('Treasury account:', treasuryAccount.toBase58());
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        console.log('User RAPR account:', userRaprAccount.toBase58());
        console.log('Creating redeem instruction...');
        try {
            const redeemIx = yield program.methods.redeemTickets()
                .accounts({
                userState: userStatePDA,
                userRaprAccount: userRaprAccount,
                userAuthority: wallet,
                treasuryAccount: treasuryAccount,
                programState: programState,
                raprMint: raprMintAddress,
                tokenProgram: TOKEN_2022_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            })
                .instruction();
            const createAccountIx = yield createRaprTokenAccountIfNeeded(wallet);
            const instructions = createAccountIx ? [createAccountIx, redeemIx] : [redeemIx];
            const tx = new Transaction().add(...instructions);
            tx.feePayer = wallet;
            const latestBlockhash = yield connection.getLatestBlockhash();
            tx.recentBlockhash = latestBlockhash.blockhash;
            console.log('Transaction built:', tx);
            return tx.serialize({ verifySignatures: false }).toString('base64');
        }
        catch (error) {
            console.error('Error creating redeem instruction:', error);
            if (error instanceof Error && error.message.includes('custom program error: 0x1770')) {
                throw new Error('Calculation overflow occurred during redemption. Please try redeeming a smaller amount.');
            }
            throw error;
        }
    });
}
function createRaprTokenAccountIfNeeded(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        try {
            yield connection.getTokenAccountBalance(userRaprAccount);
            return null; // Account exists
        }
        catch (error) {
            // Account doesn't exist, create it
            return createAssociatedTokenAccountInstruction(wallet, userRaprAccount, wallet, raprMintAddress, TOKEN_2022_PROGRAM_ID);
        }
    });
}
function calculateReward(tickets, rewardRate) {
    console.log('Calculating reward with parameters:');
    console.log('Tickets:', tickets.toString());
    console.log('Reward Rate:', rewardRate.toString());
    const baseAmount = tickets; // 1 ticket = 1 RAPR
    const amountWithDecimals = tickets.mul(new BN(1e9));
    // rewardRate is in basis points (1% = 100 basis points)
    const totalPercentage = new BN(10000).add(rewardRate);
    const totalReward = amountWithDecimals.mul(totalPercentage).div(new BN(10000));
    const additionalReward = totalReward.sub(amountWithDecimals);
    console.log('Base Amount (1:1 RAPR):', baseAmount.toString());
    console.log('Additional Reward:', additionalReward.toString());
    console.log('Total Reward:', totalReward.toString());
    return {
        baseAmount,
        additionalReward,
        totalReward
    };
}
function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}
export function initializeTicketsCard(walletState) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        console.log('Initializing tickets card with wallet:', walletState);
        const ticketsInfo = document.getElementById('ticketsInfo');
        const purchaseAmountInput = document.getElementById('purchaseAmount');
        const purchaseButton = document.getElementById('purchaseButton');
        const redeemButton = document.getElementById('redeemButton');
        let updateInterval;
        function clearTicketInfo() {
            if (ticketsInfo)
                ticketsInfo.innerHTML = '';
            if (purchaseAmountInput)
                purchaseAmountInput.disabled = true;
            if (purchaseButton)
                purchaseButton.disabled = true;
            if (redeemButton)
                redeemButton.disabled = true;
        }
        if (!walletState || !walletState.publicKey) {
            console.log('No wallet or wallet not connected');
            clearTicketInfo();
            if (ticketsInfo)
                ticketsInfo.innerHTML = 'Please connect your wallet to view ticket information.';
            return () => { };
        }
        const publicKey = walletState.publicKey;
        if (purchaseAmountInput)
            purchaseAmountInput.disabled = false;
        if (purchaseButton)
            purchaseButton.disabled = false;
        function updateTicketInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    console.log('Fetching program and user state');
                    const programState = yield getProgramState();
                    const userState = yield getUserState(publicKey);
                    const currentPrice = parseFloat(yield fetchData('price'));
                    if (programState && ticketsInfo) {
                        const ticketPrice = yield fetchData('tprice');
                        const availableTickets = yield fetchData('atickets');
                        const ticketRewardRate = yield fetchData('treward');
                        let content = `<div>Ticket Price: <span id="ticketPrice">${ticketPrice}</span> USDC

Available Tickets: <span id="availableTickets">${availableTickets}</span>

Ticket Reward Rate: <span id="ticketRewardRate">${ticketRewardRate}</span>%</div>`;
                        if (userState && userState.tickets.toNumber() > 0) {
                            const userTickets = userState.tickets.toNumber();
                            content += `<div>Your Tickets: ${userTickets}</div>`;
                            const now = Math.floor(Date.now() / 1000);
                            const purchaseTime = userState.ticketPurchaseTimestamp.toNumber();
                            const vestingDuration = programState.vestingDuration.toNumber();
                            let timeRemaining = Math.max(0, purchaseTime + vestingDuration - now);
                            content += `<div>Time to Redeem: <span id="countdown" data-time="${timeRemaining}">${formatTime(timeRemaining)}</span></div>`;
                            const { baseAmount, additionalReward, totalReward } = calculateReward(userState.tickets, programState.ticketRewardRate);
                            const totalRewardUSD = new BigNumber(totalReward.toString()).div(1e9).times(currentPrice).toFixed(2);
                            const formattedTotalReward = new BigNumber(totalReward.toString()).div(1e9).toFixed(9).replace(/\.?0+$/, "");
                            content += `<div>Total R-APR on Redemption: <span id="totalReward">${formattedTotalReward}</span>
(≈$<span id="totalRewardUSD">${totalRewardUSD}</span>)</div>
                    `;
                            if (redeemButton) {
                                redeemButton.disabled = timeRemaining > 0;
                            }
                        }
                        else {
                            content += `<div>You have no tickets.</div>`;
                            if (redeemButton)
                                redeemButton.disabled = true;
                        }
                        ticketsInfo.innerHTML = content;
                    }
                }
                catch (error) {
                    console.error('Error updating ticket information:', error);
                    if (ticketsInfo)
                        ticketsInfo.innerHTML = 'Error loading ticket information. Please try again later.';
                }
            });
        }
        yield updateTicketInfo();
        updateInterval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
            const countdownElement = document.getElementById('countdown');
            if (countdownElement) {
                const currentTime = parseInt(countdownElement.getAttribute('data-time') || '0', 10);
                if (currentTime > 0) {
                    const newTime = currentTime - 1;
                    countdownElement.setAttribute('data-time', newTime.toString());
                    countdownElement.textContent = formatTime(newTime);
                    // Enable redeem button when timer reaches 0
                    if (newTime === 0 && redeemButton) {
                        redeemButton.disabled = false;
                    }
                }
            }
            const ticketPrice = yield fetchData('tprice');
            const availableTickets = yield fetchData('atickets');
            const ticketRewardRate = yield fetchData('treward');
            const ticketPriceElement = document.getElementById('ticketPrice');
            const availableTicketsElement = document.getElementById('availableTickets');
            const ticketRewardRateElement = document.getElementById('ticketRewardRate');
            if (ticketPriceElement)
                ticketPriceElement.textContent = ticketPrice;
            if (availableTicketsElement)
                availableTicketsElement.textContent = availableTickets;
            if (ticketRewardRateElement)
                ticketRewardRateElement.textContent = ticketRewardRate;
            const totalRewardElement = document.getElementById('totalReward');
            const totalRewardUSDElement = document.getElementById('totalRewardUSD');
            if (totalRewardElement && totalRewardUSDElement) {
                const currentPrice = parseFloat(yield fetchData('price'));
                const totalReward = new BigNumber(totalRewardElement.textContent || '0');
                const totalRewardUSD = totalReward.times(currentPrice).toFixed(2);
                totalRewardUSDElement.textContent = totalRewardUSD;
            }
        }), 1000);
        if (purchaseButton) {
            const newPurchaseButton = purchaseButton.cloneNode(true);
            (_a = purchaseButton.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(newPurchaseButton, purchaseButton);
            newPurchaseButton.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                if (!(walletState === null || walletState === void 0 ? void 0 : walletState.publicKey)) {
                    console.log('Wallet not connected');
                    createPopup('Please connect your wallet first.');
                    return;
                }
                const amount = parseFloat(purchaseAmountInput.value);
                if (isNaN(amount) || amount <= 0) {
                    console.log('Invalid purchase amount:', amount);
                    createPopup('Please enter a valid amount to purchase.');
                    return;
                }
                try {
                    console.log('Initiating ticket purchase for amount:', amount);
                    const tx = yield purchaseTickets(walletState.publicKey, amount);
                    console.log('Purchase transaction created:', tx);
                    const signedTx = yield walletState.signTransaction(Transaction.from(Buffer.from(tx, 'base64')));
                    console.log('Transaction signed');
                    const signature = yield connection.sendRawTransaction(signedTx.serialize(), {
                        skipPreflight: true
                    });
                    console.log('Transaction sent. Signature:', signature);
                    yield connection.confirmTransaction(signature);
                    console.log('Transaction confirmed');
                    createPopup('Tickets purchased successfully!');
                    yield updateTicketInfo();
                }
                catch (error) {
                    console.error('Ticket purchase error:', error);
                    if (error instanceof web3.SendTransactionError) {
                        const logs = error.logs;
                        if (logs) {
                            console.error('Transaction logs:', logs);
                        }
                    }
                    createPopup('Error while purchasing tickets.<br>Please make sure you have USDC.<br>You must redeem before purchasing additional tickets.');
                }
            }));
        }
        if (redeemButton) {
            const newRedeemButton = redeemButton.cloneNode(true);
            (_b = redeemButton.parentNode) === null || _b === void 0 ? void 0 : _b.replaceChild(newRedeemButton, redeemButton);
            newRedeemButton.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                if (!(walletState === null || walletState === void 0 ? void 0 : walletState.publicKey)) {
                    console.log('Wallet not connected');
                    createPopup('Please connect your wallet first.');
                    return;
                }
                try {
                    console.log('Confirming ticket redemption');
                    const tx = yield redeemTickets(walletState.publicKey);
                    createPopup('You are about to redeem your tickets. Do you want to proceed?', () => __awaiter(this, void 0, void 0, function* () {
                        try {
                            console.log('Redemption transaction created:', tx);
                            const signedTx = yield walletState.signTransaction(Transaction.from(Buffer.from(tx, 'base64')));
                            console.log('Transaction signed');
                            const signature = yield connection.sendRawTransaction(signedTx.serialize(), {
                                skipPreflight: true
                            });
                            console.log('Transaction sent. Signature:', signature);
                            yield connection.confirmTransaction(signature);
                            console.log('Transaction confirmed');
                            createPopup('Tickets redeemed successfully!');
                            yield updateTicketInfo();
                        }
                        catch (error) {
                            console.error('Ticket redemption error:', error);
                            createPopup("Error redeeming tickets. Please ensure you hold at least 1 R-APR token and try again.");
                        }
                    }), () => {
                        console.log('Ticket redemption cancelled');
                        createPopup('Ticket redemption cancelled.');
                    });
                }
                catch (error) {
                    console.error('Error preparing ticket redemption:', error);
                    createPopup("Error preparing ticket redemption. Please ensure you hold at least 1 R-APR token and try again.");
                }
            }));
        }
        return () => {
            if (updateInterval) {
                clearInterval(updateInterval);
            }
            clearTicketInfo();
        };
    });
}
let currentCleanup;
window.addEventListener('walletChange', () => __awaiter(void 0, void 0, void 0, function* () {
    console.log('Wallet change detected in tickets, wallet:', window.wallet);
    const ticketsCard = document.querySelector('.card[data-index="1"]');
    if (ticketsCard) {
        console.log('Reinitializing tickets card due to wallet change');
        if (currentCleanup) {
            currentCleanup();
        }
        currentCleanup = yield initializeTicketsCard(window.wallet);
    }
}));
